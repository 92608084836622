import _equal from "@superblocksteam/fast-deep-equal/es6";
import {
  ApiResponseType,
  ApplicationScope,
  DataTreeGroup,
  Organization,
} from "@superblocksteam/shared";
import { get, isObject, set } from "lodash";
import unescapeJS from "unescape-js";
import { ApiInfo } from "legacy/constants/ApiConstants";
import {
  DEFAULT_EMBED_PROPERTY_META,
  EMBED_PATH_PREFIX,
  EmbedProperty,
  EmbedPropertyAndMetaMap,
  EmbedPropertyMetaType,
} from "legacy/constants/EmbeddingConstants";
import { PageListPayload } from "legacy/constants/ReduxActionConstants";

import { PredefinedFunctionDescription } from "legacy/constants/TriggerConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { PluginType } from "legacy/entities/Action";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { AppDataState } from "legacy/reducers/entityReducers/appReducer";
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
  ReduxPageType,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import {
  MetaState,
  WidgetMetadata,
} from "legacy/reducers/entityReducers/metaReducer";
import {
  DynamicProperties,
  DynamicWidgetsLayoutState,
} from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import { UserAccessibleTheme } from "legacy/themes/types";
import { findV2Error } from "legacy/utils/ApiNotificationUtility";
import {
  DynamicPath,
  EntityWithBindings,
} from "legacy/utils/DynamicBindingTypes";
import WidgetFactory from "legacy/widgets/Factory";
import getApiExecuteOnPageLoad from "store/slices/apisV2/utils/get-api-execute-on-pageload";
import { getV2ApiName } from "store/slices/apisV2/utils/getApiIdAndName";
import {
  EventDefinition,
  EventMap,
} from "store/slices/application/events/eventConstants";
import {
  AppStateVarsAndMetaMap,
  AppStateVarWithMetaType,
  DEFAULT_STATE_VAR_META,
} from "store/slices/application/stateVars/StateConstants";
import {
  AppTimerWithMetaType,
  AppTimersMap,
} from "store/slices/application/timers/TimerConstants";
import { TimersMetaState } from "store/slices/application/timersMeta/selectors";
// TODO: It creates a circular dependency if we import from parent directory
import { Flag, Flags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { splitJSPathAdvanced } from "utils/dottedPaths";
import omit from "utils/omit";
import { AppIconWithMetaType } from "../../constants/IconConstants";
import { getEntityDynamicBindingPathList } from "../../utils/DynamicBindingUtils";
import { getAllPathsFromPropertyConfig } from "../Widget/utils";
import { emptyDataTree, scopedDataTrees } from "./DataTreeHelpers";
import type { ActionData } from "legacy/reducers/entityReducers/actionsReducer";
import type { WidgetProps } from "legacy/widgets";
import type { SharedOutputsDto } from "store/slices/apisShared";
import type { ApiDtoWithPb, ApiV2Meta } from "store/slices/apisV2/slice";

const equal = _equal as <T>(a: T, b: T) => boolean;

type PredefinedFunctionDispatcher<A extends string[]> = (
  ...args: A
) => PredefinedFunctionDescription;

type GlobalDataState = Pick<
  AppDataState,
  | "app"
  | "createdAt"
  | "deployedAt"
  | "groups"
  | "profiles"
  | "user"
  | "mode"
  | "global"
>;

export interface DataTreeAction
  extends Omit<ActionData, "data" | "config">,
    Omit<ApiInfo, "id"> {
  response: unknown;
  skippedEvaluation?: boolean;
  error: string | null;
  actionOutputs: SharedOutputsDto;
  actionId: string;
  pluginType: PluginType;
  executeOnPageLoad?: boolean;
  evaluatedDependencies: ScopedDataTreePath[];
  name: string;
  run:
    | PredefinedFunctionDispatcher<[string, string, string]>
    | Record<string, any>;
  clearResponse:
    | PredefinedFunctionDispatcher<[string, string, string]>
    | Record<string, any>;
  cancel:
    | PredefinedFunctionDispatcher<[string, string, string]>
    | Record<string, any>;
  dynamicBindingPathList: DynamicPath[];
  bindingPaths: Record<string, boolean>;
  ENTITY_TYPE: ENTITY_TYPE.ACTION;
  responseType?: ApiResponseType;
}

export type DataTreeWidget = WidgetProps & {
  bindingPaths: Record<string, boolean>;
  skippedEvaluation?: boolean;
  ENTITY_TYPE: ENTITY_TYPE.WIDGET;
};

export interface DataTreeTimer extends AppTimerWithMetaType {
  skippedEvaluation?: boolean;
  ENTITY_TYPE: ENTITY_TYPE.TIMER;
}

export interface DataTreeStateVar extends AppStateVarWithMetaType {
  skippedEvaluation?: boolean;
  bindingPaths: Record<string, boolean>;
  dynamicBindingPathList: DynamicPath[];
  ENTITY_TYPE: ENTITY_TYPE.STATE_VAR;
}

export interface DataTreeEmbedProp extends EmbedPropertyMetaType {
  skippedEvaluation?: boolean;
  bindingPaths: Record<string, boolean>;
  dynamicBindingPathList: DynamicPath[];
  ENTITY_TYPE: ENTITY_TYPE.EMBED_PROP;
}
export interface DataTreeEmbed extends EntityWithBindings {
  skippedEvaluation?: boolean;
  bindingPaths: Record<string, boolean>;
  dynamicBindingPathList: DynamicPath[];
  ENTITY_TYPE: ENTITY_TYPE.EMBEDDING;
}

export interface DataTreeEvent extends EventDefinition {
  ENTITY_TYPE: ENTITY_TYPE.CUSTOM_EVENT;
  scope: ApplicationScope;
}

export interface DataTreeGlobal extends DataTreeApp, DataTreeGroups {
  bindingPaths: Record<string, boolean>;
}

export interface DataTreeTheme extends UserAccessibleTheme {
  bindingPaths: Record<string, boolean>;
  ENTITY_TYPE: ENTITY_TYPE.THEME;
  skippedEvaluation?: boolean;
}

export interface DataTreeIcons extends AppIconWithMetaType {
  ENTITY_TYPE: ENTITY_TYPE.ICONS;
  bindingPaths: Record<string, boolean>;
  skippedEvaluation?: boolean;
}

export interface DataTreeApp extends GlobalDataState {
  ENTITY_TYPE: ENTITY_TYPE.GLOBAL;
  skippedEvaluation?: boolean;
  store: Record<string, unknown>;
  groups: DataTreeGroup[];
  URL: AppDataState["URL"] & { routeParams: Record<string, string> };
}

interface DataTreeGroups extends GlobalDataState {
  ENTITY_TYPE: ENTITY_TYPE.GLOBAL;
  skippedEvaluation?: boolean;
}

export type DataTreeObjectEntity =
  | DataTreeAction
  | DataTreeStateVar
  | DataTreeEmbed
  | DataTreeTimer
  | DataTreeWidget
  | DataTreeApp
  | DataTreeTheme
  | DataTreeIcons
  | DataTreeEvent;

export type DataTreeEntity =
  | DataTreeObjectEntity
  | PageListPayload
  | DataTreeEmbedProp
  | PredefinedFunctionDispatcher<any>;

export type EntityLocation = {
  name: string;
  scope: ApplicationScope;
};

export type ScopedDataTreePath = `${ApplicationScope}.${string}`;

export type ScopedDataTree = {
  [entityName: string]: DataTreeEntity;
};

type GlobalScopedDataTree = {
  Global?: DataTreeGlobal;
  pageList?: PageListPayload;
  theme?: DataTreeTheme;
  icons?: DataTreeIcons;
} & ScopedDataTree;

type PageScopedDataTree = {
  Embed?: DataTreeEmbed;
} & ScopedDataTree;

export type DataTree = {
  [ApplicationScope.PAGE]: PageScopedDataTree;
  [ApplicationScope.APP]: ScopedDataTree;
  [ApplicationScope.GLOBAL]: GlobalScopedDataTree;
  deletedEntities?: Array<EntityLocation>;
};

export type DataTreeMetaPaths = {
  [ApplicationScope.PAGE]: {
    [entityName: string]: { [propertyName: string]: true };
  };
  [ApplicationScope.APP]: {
    [entityName: string]: { [propertyName: string]: true };
  };
  [ApplicationScope.GLOBAL]: {
    [entityName: string]: { [propertyName: string]: true };
  };
};

export type DataTreeSeed = {
  apiEntities: Record<string, ApiDtoWithPb>;
  apiOutputs: Record<string, ApiV2Meta>;
  apiAppInfo: Record<string, ApiInfo>;
  widgets: CanvasWidgetsReduxState;
  widgetsMeta: MetaState;
  widgetsDynamics: DynamicWidgetsLayoutState;
  timers: AppTimersMap;
  timersMeta: TimersMetaState;
  stateVarsAndMeta: AppStateVarsAndMetaMap;
  embedPropsAndMeta: EmbedPropertyAndMetaMap | undefined;
  eventMap: EventMap;
  pageList: PageListPayload;
  appData: AppDataState;
  orgData: Organization;
  themeData?: UserAccessibleTheme;
  iconData?: AppIconWithMetaType;
  featureFlags: Flags;
};

export class DataTreeFactory {
  // Holds old data tree values for widgets that have not changed
  private static widgetLastUpdatedMap: Record<string, Date | undefined> = {};
  private static cachedDataTree: DataTree = emptyDataTree();
  // caches all widget properties applied before meta prop (preMeta),
  // meta props (meta), and those applied after meta props (postMeta)
  // these must be stored separately since meta properties can also be
  // derived properties and override one another unexpectedly (e.g. Table's selectedRow)
  private static cachedProps: Record<
    string,
    {
      preMeta: Record<string, unknown>;
      meta: Record<string, unknown>;
      postMeta: Record<string, unknown>;
    }
  > = {};

  /**
   * @param {DataTreeSeed}  - DataTreeSeed
   * @param [returnOnlyChanges=false] - boolean - If true, only the changed widgets will be returned.
   * @returns A DataTree object
   */
  static create({
    apiEntities,
    apiOutputs,
    apiAppInfo,
    widgets,
    widgetsMeta,
    widgetsDynamics,
    timers,
    timersMeta,
    stateVarsAndMeta,
    embedPropsAndMeta,
    pageList,
    appData,
    orgData,
    themeData,
    iconData,
    featureFlags,
    eventMap,
  }: DataTreeSeed): {
    dataTree: DataTree;
    dataTreeMetaPaths: DataTreeMetaPaths;
  } {
    const dataTree: DataTree = emptyDataTree();
    // TODO(APP_SCOPE): Move this into the data tree entities
    const dataTreeMetaPaths: DataTreeMetaPaths = {
      [ApplicationScope.APP]: {},
      [ApplicationScope.PAGE]: {},
      [ApplicationScope.GLOBAL]: {},
    };

    DataTreeFactory.addDeletions({
      widgets,
      apisV2: apiEntities,
      timers,
      stateVars: stateVarsAndMeta,
      embedProps: embedPropsAndMeta ?? {},
      events: eventMap,
      oldTree: this.cachedDataTree,
      dataTree,
    });

    DataTreeFactory.addWidgets(
      widgets,
      widgetsMeta,
      widgetsDynamics,
      dataTree,
      dataTreeMetaPaths,
      featureFlags,
    );
    DataTreeFactory.addApis(apiEntities, apiOutputs, apiAppInfo, dataTree);

    DataTreeFactory.addTimers(timers, timersMeta, dataTree);
    DataTreeFactory.addStateVars(stateVarsAndMeta, dataTree, dataTreeMetaPaths);
    eventMap &&
      featureFlags[Flag.ENABLE_CUSTOM_EVENTS] &&
      DataTreeFactory.addEvents(eventMap, dataTree);
    embedPropsAndMeta &&
      featureFlags[Flag.ENABLE_EMBED] &&
      DataTreeFactory.addEmbedding(
        embedPropsAndMeta,
        dataTree,
        dataTreeMetaPaths,
      );

    DataTreeFactory.addGlobalAndPageList(orgData, pageList, appData, dataTree);
    DataTreeFactory.addThemeData(themeData, dataTree);
    DataTreeFactory.addIconData(iconData, dataTree);

    // Copy new data tree to old data tree
    // This is done this way for performance reasons
    dataTree.deletedEntities?.forEach(({ name, scope }) => {
      delete this.cachedDataTree[scope][name];
    });

    scopedDataTrees(dataTree).forEach(([scope, entities]) => {
      Object.entries(entities).forEach(([name, entity]) => {
        this.cachedDataTree[scope][name] = entity;
      });
    });

    return { dataTree, dataTreeMetaPaths };
  }

  static clearCachedDataTree() {
    this.widgetLastUpdatedMap = {};
    this.cachedDataTree = emptyDataTree();
    this.cachedProps = {};
  }

  private static addApis(
    apiEntities: Record<string, ApiDtoWithPb>,
    apiOutputs: Record<string, ApiV2Meta>,
    apiInfos: Record<string, ApiInfo>,
    dataTree: DataTree,
  ) {
    Object.entries(apiEntities).forEach(([apiId, api]) => {
      const name = getV2ApiName(api);
      const executeOnPageLoad = getApiExecuteOnPageLoad(api?.apiPb) === true;

      const apiMeta: ApiV2Meta | undefined = apiOutputs[apiId];
      const enrichedResult = apiMeta?.enrichedExecutionResult;
      const result = apiMeta?.executionResult;
      const error = findV2Error(result);
      const apiInfo: ApiInfo | undefined = apiInfos[apiId];

      const apiDataTreeItem: DataTreeAction = {
        run: {},
        clearResponse: {},
        cancel: {},
        actionId: apiId,
        name: name,
        executeOnPageLoad: executeOnPageLoad,
        pluginType: PluginType.API,
        dynamicBindingPathList: [],
        evaluatedDependencies:
          apiMeta?.extractedBindings?.map((b) => b.dataTreePath) ?? [],
        response: result && "output" in result ? result?.output?.result : null,
        error: error ?? null,
        actionOutputs: enrichedResult?.lastOutputs ?? {},
        ENTITY_TYPE: ENTITY_TYPE.ACTION,
        isLoading: false,
        bindingPaths: {
          response: true,
          error: true,
        },
        onSuccess: apiInfo?.onSuccess,
        onError: apiInfo?.onError,
        responseType: apiInfo?.responseType,
        dynamicTriggerPathList: apiInfo?.dynamicTriggerPathList ?? [],
      };

      const scope = ApplicationScope.PAGE;
      if (!equal(this.cachedDataTree[scope][name], apiDataTreeItem)) {
        dataTree[scope][name] = apiDataTreeItem;
      }
    });
  }

  private static addWidgets(
    widgets: CanvasWidgetsReduxState,
    widgetsMeta: MetaState,
    widgetsDynamics: DynamicWidgetsLayoutState,
    dataTree: DataTree,
    dataTreeMetaPaths: DataTreeMetaPaths,
    featureFlags: Flags,
  ) {
    Object.entries(widgets).forEach(([widgetId, widgetProps]) => {
      const scope = ApplicationScope.PAGE;
      const widgetMetaProps = widgetsMeta[widgetId];
      const widgetDynamics: DynamicProperties | undefined =
        widgetsDynamics[widgetId];
      const previousWidgetData =
        this.cachedDataTree[scope][widgetProps.widgetName];

      if (previousWidgetData) {
        const { widgetChanged, dynamicsChanged, metaChanged } =
          DataTreeFactory.isWidgetChanged(
            widgetProps,
            widgetMetaProps,
            widgetDynamics,
          );

        // Only use the cache for meta updates
        if (!widgetChanged && !dynamicsChanged) {
          if (!metaChanged) {
            // No changes at all, so skip updating this widget
            return;
          } else {
            // Only meta changes, so use the cached data and reapply the meta props
            const { preMeta, postMeta } =
              this.cachedProps[widgetProps.widgetName];
            const { entity, metaPaths } = DataTreeFactory.combineProps(
              preMeta,
              widgetMetaProps,
              postMeta,
            );
            dataTree[scope][widgetProps.widgetName] = entity;
            this.widgetLastUpdatedMap[widgetProps.widgetName] = new Date();
            this.cachedProps[widgetProps.widgetName].meta = widgetMetaProps;
            dataTreeMetaPaths[ApplicationScope.PAGE][widgetProps.widgetName] =
              metaPaths;
            return;
          }
        }
      }

      const widget = omit(
        widgetProps as ReduxPageType,
        "apis",
        "timers",
        "stateVars",
      );
      // Apply dynamic properties
      if (widgetDynamics && widgetDynamics.height) {
        widget.height = widgetDynamics.height;
      }

      if (!WidgetFactory.widgetMap.has(widget.type as WidgetType)) {
        // this is a widget of an unregistered/unknown type, don't send it to the evaluator
        console.error("A widget of unknown type was encountered", widget);
        return;
      }

      const defaultMetaProps = WidgetFactory.getWidgetMetaPropertiesMap(
        widget.type as WidgetType,
      );
      const derivedPropertyMap = WidgetFactory.getWidgetDerivedPropertiesMap(
        widget.type as WidgetType,
      );
      const defaultProps = WidgetFactory.getWidgetDefaultPropertiesMap(
        widget.type as WidgetType,
      );
      const propertyPaneConfigs = getItemPropertyPaneConfig(
        widget.type as WidgetType,
      );
      const { bindingPaths } = getAllPathsFromPropertyConfig(
        widget,
        propertyPaneConfigs,
        Object.fromEntries(
          Object.keys(derivedPropertyMap).map((key) => [key, true]),
        ),
        { omitHidden: true, featureFlags },
      );
      Object.keys(defaultMetaProps).forEach((defaultPath) => {
        bindingPaths[defaultPath] = true;
      });
      const derivedProps: Record<string, any> = {};
      const dynamicBindingPathList = getEntityDynamicBindingPathList(widget);
      dynamicBindingPathList.forEach((dynamicPath) => {
        const propertyPath = dynamicPath.key;
        const propertyValue = get(widget, propertyPath);

        if (isObject(propertyValue)) {
          // Stringify this because composite controls may have bindings in the sub controls
          const stringifiedValue = JSON.stringify(propertyValue);

          // propertyPath might be nested. If so, we need to unfreeze nested paths in widget
          DataTreeFactory.forceSet(widget, propertyPath, stringifiedValue);
        }

        // All dynamic bindings are also bindings. Handles cases like the Grid where the bindings
        // are purely for internal use
        bindingPaths[propertyPath] = true;
      });
      Object.keys(derivedPropertyMap).forEach((propertyName) => {
        // TODO regex is too greedy
        const propertyValue = derivedPropertyMap[propertyName].replace(
          /this./g,
          `${widget.widgetName}.`,
        );

        // TODO: we could remove the `unescapeJS` function from here, once we:
        // 1. Refactor derived properties to remove the escaped values (such as \\n)
        // 2. Then we could do `derivedProps[propertyName] = propertyValue;`
        derivedProps[propertyName] = unescapeJS(propertyValue);

        dynamicBindingPathList.push({
          key: propertyName,
        });
        bindingPaths[propertyName] = true;
      });
      const unInitializedDefaultProps: Record<string, undefined> = {};
      Object.values(defaultProps).forEach((propertyName) => {
        if (!(propertyName in widget)) {
          unInitializedDefaultProps[propertyName] = undefined;
        }
      });

      const preMeta = { ...widget, ...defaultMetaProps };
      const meta = widgetMetaProps;
      const postMeta = {
        ...unInitializedDefaultProps,
        ...derivedProps,
        dynamicBindingPathList,
        bindingPaths,
        ENTITY_TYPE: ENTITY_TYPE.WIDGET,
      };
      const { entity, metaPaths } = DataTreeFactory.combineProps(
        preMeta,
        widgetMetaProps,
        postMeta,
      );
      dataTree[scope][widget.widgetName as string] = entity;
      dataTreeMetaPaths[ApplicationScope.PAGE][widgetProps.widgetName] =
        metaPaths;
      this.widgetLastUpdatedMap[widget.widgetName as string] =
        (widget.widgetLastChange as Date) || new Date();
      this.cachedProps[widget.widgetName as string] = {
        preMeta,
        meta,
        postMeta,
      };
    });
  }

  private static addTimers(
    timers: AppTimersMap,
    timersMeta: TimersMetaState,
    dataTree: DataTree,
  ) {
    Object.entries(timers).forEach(([timerId, timer]) => {
      const timerMetaProps = timersMeta[timer.scope][timerId] || {
        isActive: false,
      };

      const timerDataTreeItem = {
        // this only exists since old timers created before this change do not have
        // a dynamicTriggerPathList by default
        dynamicTriggerPathList: [{ key: "steps" }],
        ...timer,
        ...timerMetaProps,
        ENTITY_TYPE: ENTITY_TYPE.TIMER,
      } as DataTreeTimer;

      if (
        !equal(this.cachedDataTree[timer.scope][timer.name], timerDataTreeItem)
      ) {
        dataTree[timer.scope][timer.name] = timerDataTreeItem;
      }
    });
  }

  private static addStateVars(
    stateVarsAndMeta: AppStateVarsAndMetaMap,
    dataTree: DataTree,
    dataTreeMetaPaths: DataTreeMetaPaths,
  ) {
    Object.values(stateVarsAndMeta).forEach((stateVarAndMeta) => {
      const stateVar = stateVarAndMeta.stateVar;
      if (!stateVar) {
        // We should always have a state var.
        console.warn("Unexpected case: no state var, but have meta");
        return;
      }

      const stateVarDataTreeItem = {
        ...DEFAULT_STATE_VAR_META, // if the meta hasn't been set yet use the default
        ...stateVar,
        bindingPaths: { defaultValue: true, value: true },
        ENTITY_TYPE: ENTITY_TYPE.STATE_VAR,
      } as DataTreeStateVar;
      dataTreeMetaPaths[stateVar.scope][stateVar.name] = {};
      if (stateVarAndMeta.stateVarMeta) {
        stateVarDataTreeItem.value = stateVarAndMeta.stateVarMeta.value;
        dataTreeMetaPaths[stateVar.scope][stateVar.name].value = true;
      }

      if (
        !equal(
          this.cachedDataTree[stateVar.scope][stateVar.name],
          stateVarDataTreeItem,
        )
      ) {
        dataTree[stateVar.scope][stateVar.name] = stateVarDataTreeItem;
      }
    });
  }

  private static addEvents(eventMap: EventMap, dataTree: DataTree) {
    Object.entries(eventMap).forEach(([eventId, event]) => {
      const eventDataTreeItem = {
        ...event,
        ENTITY_TYPE: ENTITY_TYPE.CUSTOM_EVENT,
      } as DataTreeEvent;
      // convert to name as key in data tree
      const scope = event.scope;
      if (!equal(this.cachedDataTree[scope][event.name], eventDataTreeItem)) {
        dataTree[scope][event.name] = eventDataTreeItem;
      }
    });
  }

  private static addEmbedding(
    embedPropAndMetaMap: EmbedPropertyAndMetaMap,
    dataTree: DataTree,
    dataTreeMetaPaths: DataTreeMetaPaths,
  ) {
    const embedObj: Record<EmbedProperty["name"], DataTreeEmbedProp> = {};
    const bindingPaths: Record<string, boolean> = {};
    const embedPropAndMetaMapValues = Object.values(embedPropAndMetaMap);
    if (embedPropAndMetaMapValues.length === 0 && dataTree.PAGE.Embed) {
      delete dataTree.PAGE.Embed;
      return;
    }
    embedPropAndMetaMapValues.forEach((embedPropAndMeta) => {
      const embedProp = embedPropAndMeta.embedProp;
      if (!embedProp) {
        console.warn("Unexpected case: no embed prop, but have meta");
        return;
      }
      bindingPaths[embedProp.name] = true;
      bindingPaths[`${embedProp.name}.defaultValue`] = true;
      bindingPaths[`${embedProp.name}.value`] = true;
      embedObj[embedProp.name] = {
        ...DEFAULT_EMBED_PROPERTY_META,
        ...embedProp,
        bindingPaths: { defaultValue: true, value: true },
        ENTITY_TYPE: ENTITY_TYPE.EMBED_PROP,
      } as DataTreeEmbedProp;

      dataTreeMetaPaths[ApplicationScope.PAGE][
        `${EMBED_PATH_PREFIX}.${embedProp.name}`
      ] = {};
      if (embedPropAndMeta.embedPropMeta) {
        embedObj[embedProp.name].value = embedPropAndMeta.embedPropMeta.value;
        dataTreeMetaPaths[ApplicationScope.PAGE][
          `${EMBED_PATH_PREFIX}.${embedProp.name}`
        ].value = true;
      }
    });

    const embedDataTreeItem: DataTreeEmbed = {
      ...embedObj,
      dynamicBindingPathList: Object.keys(bindingPaths).map((key) => ({
        key,
      })),
      bindingPaths,
      ENTITY_TYPE: ENTITY_TYPE.EMBEDDING,
    };
    if (!equal(this.cachedDataTree.PAGE.Embed, embedDataTreeItem)) {
      dataTree.PAGE.Embed = embedDataTreeItem;
    }
  }

  private static addGlobalAndPageList(
    orgData: Organization,
    pageList: PageListPayload,
    appData: AppDataState,
    dataTree: DataTree,
  ) {
    const groups: DataTreeGroup[] =
      orgData?.groups.map((group: any) => new DataTreeGroup(group)) ?? [];

    const bindingPaths = Object.keys(appData).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {
        groups: true,
      } as Record<string, boolean>,
    );

    const routeParams = appData.currentRoute?.params ?? {};
    const currentRoute = appData.currentRoute?.routeDef.path ?? "";
    const globalValue = {
      ...appData,
      URL: {
        routeParams,
        currentRoute,
        ...appData.URL,
      },
      groups,
      bindingPaths,
      ENTITY_TYPE: ENTITY_TYPE.GLOBAL,
    } as DataTreeGlobal;

    //already in Global.profiles.selected
    delete (globalValue as any)["selectedProfileId"];
    delete bindingPaths["selectedProfileId"];

    if (!equal(this.cachedDataTree.GLOBAL.pageList, pageList)) {
      dataTree.GLOBAL.pageList = pageList;
    }
    if (!equal(this.cachedDataTree.GLOBAL.Global, globalValue)) {
      dataTree.GLOBAL.Global = globalValue;
    }
  }

  private static addThemeData(
    themeData: undefined | UserAccessibleTheme,
    dataTree: DataTree,
  ) {
    if (!themeData) return;

    const themeValue = {
      ...themeData,
      bindingPaths: {
        colors: true,
        mode: true,
      },
      ENTITY_TYPE: ENTITY_TYPE.THEME,
    } as DataTreeTheme;
    if (!equal(this.cachedDataTree.GLOBAL.theme, themeValue)) {
      dataTree.GLOBAL.theme = themeValue;
    }
  }

  private static addIconData(
    iconData: undefined | AppIconWithMetaType,
    dataTree: DataTree,
  ) {
    if (!iconData) return;

    const iconValue = {
      ...iconData,
      bindingPaths: {
        icons: true,
      },
      ENTITY_TYPE: ENTITY_TYPE.ICONS,
    } as DataTreeIcons;

    if (!this.cachedDataTree.GLOBAL.icons) {
      // icon value is a constant, so we don't need to check for equality
      dataTree.GLOBAL.icons = iconValue;
    }
  }

  private static addDeletions({
    widgets,
    apisV2,
    timers,
    stateVars,
    embedProps,
    events,
    oldTree,
    dataTree,
  }: {
    widgets: CanvasWidgetsReduxState;
    apisV2: Record<string, ApiDtoWithPb>;
    timers: AppTimersMap;
    stateVars: AppStateVarsAndMetaMap;
    embedProps: EmbedPropertyAndMetaMap;
    events: EventMap;
    oldTree: DataTree;
    dataTree: DataTree;
  }) {
    const deletions: DataTree = emptyDataTree();
    scopedDataTrees(oldTree)
      .flatMap(([scope, entities]) =>
        Object.entries(entities).map(
          ([name, e]: [string, DataTreeEntity]) => [scope, name, e] as const,
        ),
      )
      .filter(([, , entity]) => {
        if ("ENTITY_TYPE" in entity) {
          return (
            // TODO: figure out how to clear EmbedProp empty object in tree after deletion
            entity.ENTITY_TYPE === ENTITY_TYPE.WIDGET ||
            entity.ENTITY_TYPE === ENTITY_TYPE.ACTION ||
            entity.ENTITY_TYPE === ENTITY_TYPE.TIMER ||
            entity.ENTITY_TYPE === ENTITY_TYPE.STATE_VAR ||
            entity.ENTITY_TYPE === ENTITY_TYPE.CUSTOM_EVENT
          );
        }
        return false;
      })
      .forEach(([scope, name, value]) => {
        deletions[scope][name] = value as DataTreeEntity;
      });

    Object.values(widgets).forEach((e) => {
      const scope = ApplicationScope.PAGE;
      delete deletions[scope][e.widgetName];
    });
    Object.values(apisV2).forEach((e) => {
      const scope = ApplicationScope.PAGE;
      if (getV2ApiName(e)) delete deletions[scope][getV2ApiName(e)];
    });
    Object.values(timers).forEach((e) => {
      delete deletions[e.scope][e?.name];
    });
    Object.values(stateVars).forEach((e) => {
      delete deletions[e?.stateVar?.scope][e?.stateVar?.name];
    });
    Object.values(events).forEach((e) => {
      delete deletions[e.scope][e.name];
    });

    dataTree.deletedEntities = Object.entries(deletions).flatMap(
      ([scope, entities]) =>
        Object.keys(entities).map((name) => ({
          name,
          scope: scope as ApplicationScope,
        })),
    );
  }

  private static isWidgetChanged(
    widgetProps: FlattenedWidgetProps,
    widgetMeta: WidgetMetadata,
    widgetDynamics: DynamicProperties | undefined,
  ) {
    const scope = ApplicationScope.PAGE;
    const previousTime = this.widgetLastUpdatedMap[widgetProps.widgetName];

    const widgetLastChange = widgetProps.widgetLastChange;
    const metaLastChange = widgetMeta?.metaLastChange;

    const widgetHasNotChanged =
      widgetLastChange && previousTime && widgetLastChange <= previousTime;

    const metaHasNotChanged =
      widgetMeta === undefined ||
      (metaLastChange && previousTime && metaLastChange <= previousTime);

    const previousWidgetData = this.cachedDataTree[scope][
      widgetProps.widgetName
    ] as DataTreeWidget | undefined;
    const newHeight = widgetDynamics?.height || widgetProps.height;
    const oldHeight = previousWidgetData?.height;
    const heightHasNotChanged = equal(newHeight, oldHeight);

    return {
      widgetChanged: !widgetHasNotChanged,
      dynamicsChanged: !heightHasNotChanged,
      metaChanged: !metaHasNotChanged,
    };
  }

  private static combineProps(
    preMeta: Record<string, unknown>,
    meta: Record<string, unknown> | undefined,
    postMeta: Record<string, unknown>,
  ) {
    const entity = {
      ...preMeta,
      ...meta,
      ...postMeta,
    } as unknown as DataTreeEntity;

    const metaPaths: { [entityName: string]: true } = {};
    Object.keys(meta ?? {}).forEach((propertyName) => {
      if (!Object.prototype.hasOwnProperty.call(postMeta, propertyName)) {
        metaPaths[propertyName] = true;
      }
    });

    return { entity, metaPaths };
  }

  /**
   * Sets the value at propertyPath of widget.
   * Works even when the widget is partially frozen
   *
   * @param widget
   * @param propertyPath
   * @param value
   */
  private static forceSet(
    widget: Omit<ReduxPageType, "apis" | "stateVars" | "timers">,
    propertyPath: string,
    value: string,
  ) {
    const propertyPathParts = splitJSPathAdvanced(propertyPath, []);
    if (
      propertyPathParts.length > 1 &&
      Object.isFrozen(widget[propertyPathParts[0]])
    ) {
      widget[propertyPathParts[0]] = fastClone(widget[propertyPathParts[0]]);
    }

    set(widget, propertyPathParts, value);
  }
}
